import {observe} from '@github/selector-observer'

observe(".js-structured-issue-multi-select-option[aria-checked='true']", {
  add: element => processSelection(element as HTMLInputElement),
  remove: element => processSelection(element as HTMLInputElement),
})

function processSelection(selection: HTMLInputElement) {
  updateSelectionText(selection)
  updateRequiredStatus(selection)
}

function updateSelectionText(selection: HTMLInputElement) {
  const multiSelectContainer = selection.closest('.form-group')

  if (multiSelectContainer) {
    const selectedOptions = multiSelectContainer.querySelectorAll<HTMLInputElement>(
      ".js-structured-issue-multi-select-option[aria-checked='true']",
    )
    const summaryContainer = multiSelectContainer?.querySelector<HTMLElement>(
      '.js-structured-issue-multi-select-summary-button > span[data-menu-button]',
    )

    if (summaryContainer) {
      const selectedValues = []

      for (const selectedOption of selectedOptions) {
        const input = selectedOption.querySelector('input')

        if (input) {
          selectedValues.push(input.value)
        }
      }

      summaryContainer.textContent = selectedValues.join(', ')
    }
  }
}

function updateRequiredStatus(selection: HTMLInputElement) {
  if (selection.getAttribute('data-required') !== 'true') return

  const multiSelectContainer = selection.closest('.form-group')

  if (multiSelectContainer) {
    const options = multiSelectContainer.querySelectorAll<HTMLInputElement>(
      ".js-structured-issue-multi-select-option[data-required='true']",
    )

    for (const option of options) {
      const input = option.querySelector<HTMLInputElement>('input')

      if (input) {
        if (selection.getAttribute('aria-checked') === 'true') {
          input.removeAttribute('required')
        } else if (Array.from(options).every(o => o.getAttribute('aria-checked') !== 'true')) {
          input.setAttribute('required', 'required')
        }
      }
    }
  }
}
